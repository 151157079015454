export var imageRenditions = {
  desktopL: {
    h: '574',
    w: '1450'
  },
  desktop: {
    h: '574',
    w: '1238'
  },
  tabletL: {
    h: '573',
    w: '1024'
  },
  tabletP: {
    h: '450',
    w: '768'
  },
  mobile: {
    h: '450',
    w: '375'
  }
};
export var peackingImageRenditions = {
  desktopL: {
    h: '94',
    w: '208'
  },
  desktop: {
    h: '94',
    w: '208'
  },
  tabletL: {
    h: '94',
    w: '208'
  },
  tabletP: {
    h: '94',
    w: '208'
  },
  mobile: {
    h: '94',
    w: '208'
  }
};