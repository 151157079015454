export var imageRenditions = {
  desktopL: {
    w: '1253',
    h: '564'
  },
  desktop: {
    w: '1070',
    h: '482'
  },
  tabletL: {
    w: '964',
    h: '482'
  },
  tabletP: {
    w: '540',
    h: '405'
  },
  mobile: {
    w: '320',
    h: '214'
  }
};
export var videoImageRenditions = {
  desktopL: {
    w: '1128',
    h: '500'
  },
  desktop: {
    w: '963',
    h: '500'
  },
  tabletL: {
    w: '868',
    h: '500'
  },
  tabletP: {
    w: '779',
    h: '430'
  },
  mobile: {
    w: '600',
    h: '410'
  }
};