import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["classNameAnchor", "classNameImage", "classNamePara", "classNameHeading", "headingType", "imageDetails", "ParaText", "src", "alt", "hrefAnchor", "hrefBehavior", "dataAnalytics", "srcSetSetting", "renderSVG", "anchorLink"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
// import slugify from 'url-slug';
import urlSlug from 'url-slug';
import styles from './ImageLink.style';
import Image from '../../atoms/Image';
import Anchor from '../../atoms/Anchor';
import Para from '../../atoms/Para';
import Mailchimp from '../../atoms/Mailchimp';
import { isSvgUrlValid } from '../../../utils/utilityFunction';
var ImageLink = function ImageLink(_ref) {
  var classNameAnchor = _ref.classNameAnchor,
    classNameImage = _ref.classNameImage,
    classNamePara = _ref.classNamePara,
    classNameHeading = _ref.classNameHeading,
    headingType = _ref.headingType,
    imageDetails = _ref.imageDetails,
    ParaText = _ref.ParaText,
    src = _ref.src,
    alt = _ref.alt,
    hrefAnchor = _ref.hrefAnchor,
    hrefBehavior = _ref.hrefBehavior,
    dataAnalytics = _ref.dataAnalytics,
    srcSetSetting = _ref.srcSetSetting,
    renderSVG = _ref.renderSVG,
    anchorLink = _ref.anchorLink,
    otherProps = _objectWithoutProperties(_ref, _excluded);
  var imageUrl = imageDetails && imageDetails.image && imageDetails.image.url || '';
  var renderSvgFlag = renderSVG && isSvgUrlValid(imageUrl);
  var isCustomCode = imageDetails && (imageDetails === null || imageDetails === void 0 ? void 0 : imageDetails.__typename) === 'Mailchimp';
  return __jsx("div", {
    className: "nva-image-link molecule ImageLinkContainer"
  }, __jsx(Anchor, {
    title: ParaText,
    href: hrefAnchor,
    to: hrefAnchor,
    ctaBehavior: hrefBehavior || 'Open in the same page',
    className: "nva-image-link__anchor ".concat(classNameAnchor),
    "data-analytics-type": dataAnalytics !== null && dataAnalytics !== void 0 && dataAnalytics.type ? dataAnalytics.type : '',
    "data-analytics-value": dataAnalytics !== null && dataAnalytics !== void 0 && dataAnalytics.value ? dataAnalytics.value : '',
    "data-analytics-variation": dataAnalytics !== null && dataAnalytics !== void 0 && dataAnalytics.variation ? dataAnalytics.variation : ''
  }, __jsx("div", {
    className: "imageWrapper"
  }, renderSvgFlag ? __jsx("img", {
    src: imageUrl,
    type: "image/svg+xml",
    className: "nva-image-link__image"
  }) : isCustomCode ? __jsx(Mailchimp, {
    className: "nva-image-link__custom-code",
    mailchimpdata: imageDetails
  }) : __jsx(Image, {
    className: "nva-image-link__image ".concat(classNameImage),
    src: imageUrl,
    alt: imageDetails && imageDetails.altText,
    title: imageDetails && (imageDetails.title || imageDetails.altText),
    srcSetSettingImg: srcSetSetting,
    "aria-hidden": ParaText.trim() ? "true" : null
  })), __jsx("div", {
    className: "nva-image-link__para ".concat(classNamePara)
  }, __jsx(Para, {
    className: "nva-image-link__heading ".concat(classNameHeading),
    contentfulLivePreview: otherProps === null || otherProps === void 0 ? void 0 : otherProps.contentfulLivePreview
  }, ParaText))));
};
export default styled(ImageLink).withConfig({
  componentId: "sc-1qwbpoj-0"
})(["", ";"], styles);
export { ImageLink as ImageLinkVanilla };