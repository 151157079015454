import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import fontColor from '../../../constants/globalConstants';
import { setNonCtaAnchorStyling } from '../../../utils/utilityFunction';
var styles = css(["background-color:var(--sc-form--bg-color-document-form,", ");.document-heading{margin-bottom:var(--sc-spacing-2,6px);color:", ";@media (min-width:", "){margin-bottom:var(--sc-spacing-4,20px);}@media (min-width:", "){margin-bottom:var(--sc-spacing-3,15px);}}&.download-form{padding:40px 20px;@media (min-width:", "){padding:var(--sc-spacing-9,60px) var(--sc-spacing-16,114px);}@media (min-width:", "){padding:var(--sc-spacing-12,80px) var(--sc-spacing-6,32px);}@media (min-width:", "){padding:var(--sc-spacing-12,80px) 148px;}}.document-container{display:flex;flex-wrap:wrap;margin-top:var(--sc-spacing-9,60px);@media (min-width:", "){margin-top:var(--sc-spacing-12,80px);}}.download-description{width:70%;margin-bottom:var(--sc-spacing-7,40px);h3{margin-bottom:0.95rem;}h2,h3,h4,ul,ol,p{color:", ";}a:not(.sg-btn-cpnt){", "}@media (min-width:", "){width:60%;padding-right:var(--sc-spacing-8,50px);}}.download-section{width:100%;h4{color:", ";}@media (min-width:", "){width:30%;}}.document-sub-heading,.download-section .download-heading{color:", ";}.download-label{margin-left:var(--sc-spacing-3,15px);@media (min-width:", "){margin-left:var(--sc-spacing-4,20px);}}.download-list{display:flex;align-items:center;width:100%;margin-top:var(--sc-spacing-5,30px);.download-label{color:", ";}@media (min-width:", "){margin-top:var(--sc-spacing-7,40px);}}.download-icon{width:56px;height:56px;path{fill:", ";stroke:", ";}}@media (min-width:", "){padding:var(--sc-spacing-12,80px) 0;}"], function (props) {
  var _props$theme, _props$theme$form;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : (_props$theme$form = _props$theme.form) === null || _props$theme$form === void 0 ? void 0 : _props$theme$form.documentFormBgColor;
}, function (props) {
  var _props$theme2, _props$theme2$form;
  return props && props.documentFormsData && props.documentFormsData.fontColor === fontColor.light ? "var(--sc-form--color-text-light, ".concat((_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : (_props$theme2$form = _props$theme2.form) === null || _props$theme2$form === void 0 ? void 0 : _props$theme2$form.textColorLight, ")") : '';
}, breakPoints.tabletP, breakPoints.desktop, breakPoints.tabletP, breakPoints.desktop, breakPoints.desktopM, breakPoints.desktop, function (props) {
  var _props$theme3, _props$theme3$form;
  return props && props.documentFormsData && props.documentFormsData.fontColor === fontColor.light ? "var(--sc-form--color-text-light, ".concat((_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : (_props$theme3$form = _props$theme3.form) === null || _props$theme3$form === void 0 ? void 0 : _props$theme3$form.textColorLight, ")") : '';
}, function (props) {
  var _props$documentFormsD, _props$theme4;
  return ((_props$documentFormsD = props.documentFormsData) === null || _props$documentFormsD === void 0 ? void 0 : _props$documentFormsD.fontColor) === fontColor.light ? setNonCtaAnchorStyling((_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.form) : '';
}, breakPoints.desktop, function (props) {
  var _props$theme5, _props$theme5$form, _props$theme6, _props$theme6$form;
  return (_props$theme5 = props.theme) !== null && _props$theme5 !== void 0 && (_props$theme5$form = _props$theme5.form) !== null && _props$theme5$form !== void 0 && _props$theme5$form.documentFormH4Color ? "var(--sc-form--color-document-form-h4, ".concat((_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : (_props$theme6$form = _props$theme6.form) === null || _props$theme6$form === void 0 ? void 0 : _props$theme6$form.documentFormH4Color, ")") : '';
}, breakPoints.desktop, function (props) {
  var _props$theme7, _props$theme7$form;
  return props && props.documentFormsData && props.documentFormsData.fontColor === fontColor.light ? "var(--sc-form--color-text-light, ".concat((_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : (_props$theme7$form = _props$theme7.form) === null || _props$theme7$form === void 0 ? void 0 : _props$theme7$form.textColorLight, ")") : '';
}, breakPoints.desktop, function (props) {
  var _props$theme8, _props$theme8$form, _props$theme9, _props$theme9$form;
  return props && props.documentFormsData && props.documentFormsData.fontColor === fontColor.light ? "var(--sc-form--color-text-light, ".concat((_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : (_props$theme8$form = _props$theme8.form) === null || _props$theme8$form === void 0 ? void 0 : _props$theme8$form.textColorLight, ")") : "var(--sc-form--color-link, ".concat((_props$theme9 = props.theme) === null || _props$theme9 === void 0 ? void 0 : (_props$theme9$form = _props$theme9.form) === null || _props$theme9$form === void 0 ? void 0 : _props$theme9$form.linkColor, ")");
}, breakPoints.desktop, function (props) {
  var _props$theme10, _props$theme10$form, _props$theme11, _props$theme11$form;
  return props && props.documentFormsData && props.documentFormsData.fontColor === fontColor.light ? "var(--sc-form--color-text-light, ".concat((_props$theme10 = props.theme) === null || _props$theme10 === void 0 ? void 0 : (_props$theme10$form = _props$theme10.form) === null || _props$theme10$form === void 0 ? void 0 : _props$theme10$form.textColorLight, ")") : "var(--sc-form--color-link, ".concat((_props$theme11 = props.theme) === null || _props$theme11 === void 0 ? void 0 : (_props$theme11$form = _props$theme11.form) === null || _props$theme11$form === void 0 ? void 0 : _props$theme11$form.linkColor, ")");
}, function (props) {
  var _props$theme12, _props$theme12$form, _props$theme13, _props$theme13$form;
  return props && props.documentFormsData && props.documentFormsData.fontColor === fontColor.light ? "var(--sc-form--color-text-light, ".concat((_props$theme12 = props.theme) === null || _props$theme12 === void 0 ? void 0 : (_props$theme12$form = _props$theme12.form) === null || _props$theme12$form === void 0 ? void 0 : _props$theme12$form.textColorLight, ")") : "var(--sc-form--color-link, ".concat((_props$theme13 = props.theme) === null || _props$theme13 === void 0 ? void 0 : (_props$theme13$form = _props$theme13.form) === null || _props$theme13$form === void 0 ? void 0 : _props$theme13$form.linkColor, ")");
}, breakPoints.desktop);
export default styles;