import { css } from 'styled-components';
import { getBackgroundStyle, isBgColorStyleLight, setNonCtaAnchorStyling } from '../../../utils/utilityFunction';
import fontColor from '../../../constants/globalConstants';
var backgroundStyles = '';
var styles = css(["", ";.image-slider{padding-top:var(--sc-spacing-7,40px);padding-bottom:var(--sc-spacing-9,57px);}ol,ul,h2,h3,.heading_class,.heading_four,.para_class p{color:", ";}a:not(.sg-btn-cpnt){", "}.slick-slide > div{border-radius:", ";overflow:hidden;img{margin-bottom:-4px;}}.slick-dots{position:absolute;bottom:-45px;button{background-color:", ";}.slick-dots-custom{background-color:", ";}.slick-active{button{background-color:", ";opacity:0.7;&:before{color:", ";}}.slick-dots-custom{background-color:", ";opacity:0.7;&:before{color:", ";}}}}"], function (props) {
  // backgroundoverride logic removed due to isBgColorStyleLight
  if (props.imageSliderData) {
    var _props$theme, _props$theme$imageSli, _props$theme2, _props$theme3;
    var overrideBackground = props.imageSliderData.overrideBackground;
    var overrideId = overrideBackground ? overrideBackground.backgroundId : '';
    backgroundStyles = getBackgroundStyle(overrideId, '', (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : (_props$theme$imageSli = _props$theme.imageSlider) === null || _props$theme$imageSli === void 0 ? void 0 : _props$theme$imageSli.primaryBgColor, (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.palette, (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.textures);
    return backgroundStyles;
  }
}, function (props) {
  var _props$imageSliderDat, _props$imageSliderDat2, _props$imageSliderDat3;
  if ((props === null || props === void 0 ? void 0 : (_props$imageSliderDat = props.imageSliderData) === null || _props$imageSliderDat === void 0 ? void 0 : _props$imageSliderDat.fontColor) === null) {
    var _props$theme4, _props$theme4$imageSl, _props$theme5, _props$theme5$imageSl;
    return isBgColorStyleLight(backgroundStyles) ? "var(--sc-image-slider--text-color-dark, ".concat((_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : (_props$theme4$imageSl = _props$theme4.imageSlider) === null || _props$theme4$imageSl === void 0 ? void 0 : _props$theme4$imageSl.textColorDark, ")") : "var(--sc-image-slider--text-color-light, ".concat((_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : (_props$theme5$imageSl = _props$theme5.imageSlider) === null || _props$theme5$imageSl === void 0 ? void 0 : _props$theme5$imageSl.textColorLight, ")");
  }
  if ((props === null || props === void 0 ? void 0 : (_props$imageSliderDat2 = props.imageSliderData) === null || _props$imageSliderDat2 === void 0 ? void 0 : _props$imageSliderDat2.fontColor) === fontColor.light) {
    var _props$theme6, _props$theme6$imageSl;
    return "var(--sc-image-slider--text-color-light, ".concat((_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : (_props$theme6$imageSl = _props$theme6.imageSlider) === null || _props$theme6$imageSl === void 0 ? void 0 : _props$theme6$imageSl.textColorLight, ")");
  } else if ((props === null || props === void 0 ? void 0 : (_props$imageSliderDat3 = props.imageSliderData) === null || _props$imageSliderDat3 === void 0 ? void 0 : _props$imageSliderDat3.fontColor) === fontColor.dark) {
    var _props$theme7, _props$theme7$imageSl;
    return "var(--sc-image-slider--text-color-dark, ".concat((_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : (_props$theme7$imageSl = _props$theme7.imageSlider) === null || _props$theme7$imageSl === void 0 ? void 0 : _props$theme7$imageSl.textColorDark, ")");
  }
}, function (props) {
  var _props$imageSliderDat4, _props$imageSliderDat5, _props$theme8;
  return (props === null || props === void 0 ? void 0 : (_props$imageSliderDat4 = props.imageSliderData) === null || _props$imageSliderDat4 === void 0 ? void 0 : _props$imageSliderDat4.fontColor) === fontColor.light || ((_props$imageSliderDat5 = props.imageSliderData) === null || _props$imageSliderDat5 === void 0 ? void 0 : _props$imageSliderDat5.fontColor) === null && !isBgColorStyleLight(backgroundStyles) ? setNonCtaAnchorStyling((_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : _props$theme8.imageSlider) : '';
}, function (props) {
  var _props$theme9, _props$theme9$imageSl;
  return (_props$theme9 = props.theme) === null || _props$theme9 === void 0 ? void 0 : (_props$theme9$imageSl = _props$theme9.imageSlider) === null || _props$theme9$imageSl === void 0 ? void 0 : _props$theme9$imageSl.cardRadius;
}, function (props) {
  var _props$imageSliderDat6, _props$imageSliderDat7, _props$imageSliderDat8;
  if ((props === null || props === void 0 ? void 0 : (_props$imageSliderDat6 = props.imageSliderData) === null || _props$imageSliderDat6 === void 0 ? void 0 : _props$imageSliderDat6.fontColor) === null) {
    var _props$theme10, _props$theme10$imageS, _props$theme11, _props$theme11$imageS;
    return isBgColorStyleLight(backgroundStyles) ? "var(--sc-image-slider--text-color-dark, ".concat((_props$theme10 = props.theme) === null || _props$theme10 === void 0 ? void 0 : (_props$theme10$imageS = _props$theme10.imageSlider) === null || _props$theme10$imageS === void 0 ? void 0 : _props$theme10$imageS.textColorDark, ")") : "var(--sc-image-slider--text-color-light, ".concat((_props$theme11 = props.theme) === null || _props$theme11 === void 0 ? void 0 : (_props$theme11$imageS = _props$theme11.imageSlider) === null || _props$theme11$imageS === void 0 ? void 0 : _props$theme11$imageS.textColorLight, ")");
  }
  if ((props === null || props === void 0 ? void 0 : (_props$imageSliderDat7 = props.imageSliderData) === null || _props$imageSliderDat7 === void 0 ? void 0 : _props$imageSliderDat7.fontColor) === fontColor.light) {
    var _props$theme12, _props$theme12$imageS;
    return "var(--sc-image-slider--text-color-light, ".concat((_props$theme12 = props.theme) === null || _props$theme12 === void 0 ? void 0 : (_props$theme12$imageS = _props$theme12.imageSlider) === null || _props$theme12$imageS === void 0 ? void 0 : _props$theme12$imageS.textColorLight, ")");
  } else if ((props === null || props === void 0 ? void 0 : (_props$imageSliderDat8 = props.imageSliderData) === null || _props$imageSliderDat8 === void 0 ? void 0 : _props$imageSliderDat8.fontColor) === fontColor.dark) {
    var _props$theme13, _props$theme13$imageS;
    return "var(--sc-image-slider--text-color-dark, ".concat((_props$theme13 = props.theme) === null || _props$theme13 === void 0 ? void 0 : (_props$theme13$imageS = _props$theme13.imageSlider) === null || _props$theme13$imageS === void 0 ? void 0 : _props$theme13$imageS.textColorDark, ")");
  }
}, function (props) {
  var _props$imageSliderDat9, _props$imageSliderDat10, _props$imageSliderDat11;
  if ((props === null || props === void 0 ? void 0 : (_props$imageSliderDat9 = props.imageSliderData) === null || _props$imageSliderDat9 === void 0 ? void 0 : _props$imageSliderDat9.fontColor) === null) {
    var _props$theme14, _props$theme14$imageS, _props$theme15, _props$theme15$imageS;
    return isBgColorStyleLight(backgroundStyles) ? "var(--sc-image-slider--text-color-dark, ".concat((_props$theme14 = props.theme) === null || _props$theme14 === void 0 ? void 0 : (_props$theme14$imageS = _props$theme14.imageSlider) === null || _props$theme14$imageS === void 0 ? void 0 : _props$theme14$imageS.textColorDark, ")") : "var(--sc-image-slider--text-color-light, ".concat((_props$theme15 = props.theme) === null || _props$theme15 === void 0 ? void 0 : (_props$theme15$imageS = _props$theme15.imageSlider) === null || _props$theme15$imageS === void 0 ? void 0 : _props$theme15$imageS.textColorLight, ")");
  }
  if ((props === null || props === void 0 ? void 0 : (_props$imageSliderDat10 = props.imageSliderData) === null || _props$imageSliderDat10 === void 0 ? void 0 : _props$imageSliderDat10.fontColor) === fontColor.light) {
    var _props$theme16, _props$theme16$imageS;
    return "var(--sc-image-slider--text-color-light, ".concat((_props$theme16 = props.theme) === null || _props$theme16 === void 0 ? void 0 : (_props$theme16$imageS = _props$theme16.imageSlider) === null || _props$theme16$imageS === void 0 ? void 0 : _props$theme16$imageS.textColorLight, ")");
  } else if ((props === null || props === void 0 ? void 0 : (_props$imageSliderDat11 = props.imageSliderData) === null || _props$imageSliderDat11 === void 0 ? void 0 : _props$imageSliderDat11.fontColor) === fontColor.dark) {
    var _props$theme17, _props$theme17$imageS;
    return "var(--sc-image-slider--text-color-dark, ".concat((_props$theme17 = props.theme) === null || _props$theme17 === void 0 ? void 0 : (_props$theme17$imageS = _props$theme17.imageSlider) === null || _props$theme17$imageS === void 0 ? void 0 : _props$theme17$imageS.textColorDark, ")");
  }
}, function (props) {
  var _props$imageSliderDat12, _props$imageSliderDat13, _props$imageSliderDat14;
  if ((props === null || props === void 0 ? void 0 : (_props$imageSliderDat12 = props.imageSliderData) === null || _props$imageSliderDat12 === void 0 ? void 0 : _props$imageSliderDat12.fontColor) === null) {
    var _props$theme18, _props$theme18$imageS, _props$theme19, _props$theme19$imageS;
    return isBgColorStyleLight(backgroundStyles) ? "var(--sc-image-slider--text-color-dark, ".concat((_props$theme18 = props.theme) === null || _props$theme18 === void 0 ? void 0 : (_props$theme18$imageS = _props$theme18.imageSlider) === null || _props$theme18$imageS === void 0 ? void 0 : _props$theme18$imageS.textColorDark, ")") : "var(--sc-image-slider--text-color-light, ".concat((_props$theme19 = props.theme) === null || _props$theme19 === void 0 ? void 0 : (_props$theme19$imageS = _props$theme19.imageSlider) === null || _props$theme19$imageS === void 0 ? void 0 : _props$theme19$imageS.textColorLight, ")");
  }
  if ((props === null || props === void 0 ? void 0 : (_props$imageSliderDat13 = props.imageSliderData) === null || _props$imageSliderDat13 === void 0 ? void 0 : _props$imageSliderDat13.fontColor) === fontColor.light) {
    var _props$theme20, _props$theme20$imageS;
    return "var(--sc-image-slider--text-color-light, ".concat((_props$theme20 = props.theme) === null || _props$theme20 === void 0 ? void 0 : (_props$theme20$imageS = _props$theme20.imageSlider) === null || _props$theme20$imageS === void 0 ? void 0 : _props$theme20$imageS.textColorLight, ")");
  } else if ((props === null || props === void 0 ? void 0 : (_props$imageSliderDat14 = props.imageSliderData) === null || _props$imageSliderDat14 === void 0 ? void 0 : _props$imageSliderDat14.fontColor) === fontColor.dark) {
    var _props$theme21, _props$theme21$imageS;
    return "var(--sc-image-slider--text-color-dark, ".concat((_props$theme21 = props.theme) === null || _props$theme21 === void 0 ? void 0 : (_props$theme21$imageS = _props$theme21.imageSlider) === null || _props$theme21$imageS === void 0 ? void 0 : _props$theme21$imageS.textColorDark, ")");
  }
}, function (props) {
  var _props$imageSliderDat15, _props$imageSliderDat16, _props$imageSliderDat17;
  if ((props === null || props === void 0 ? void 0 : (_props$imageSliderDat15 = props.imageSliderData) === null || _props$imageSliderDat15 === void 0 ? void 0 : _props$imageSliderDat15.fontColor) === null) {
    var _props$theme22, _props$theme22$imageS, _props$theme23, _props$theme23$imageS;
    return isBgColorStyleLight(backgroundStyles) ? "var(--sc-image-slider--text-color-dark, ".concat((_props$theme22 = props.theme) === null || _props$theme22 === void 0 ? void 0 : (_props$theme22$imageS = _props$theme22.imageSlider) === null || _props$theme22$imageS === void 0 ? void 0 : _props$theme22$imageS.textColorDark, ")") : "var(--sc-image-slider--text-color-light, ".concat((_props$theme23 = props.theme) === null || _props$theme23 === void 0 ? void 0 : (_props$theme23$imageS = _props$theme23.imageSlider) === null || _props$theme23$imageS === void 0 ? void 0 : _props$theme23$imageS.textColorLight, ")");
  }
  if ((props === null || props === void 0 ? void 0 : (_props$imageSliderDat16 = props.imageSliderData) === null || _props$imageSliderDat16 === void 0 ? void 0 : _props$imageSliderDat16.fontColor) === fontColor.light) {
    var _props$theme24, _props$theme24$imageS;
    return "var(--sc-image-slider--text-color-light, ".concat((_props$theme24 = props.theme) === null || _props$theme24 === void 0 ? void 0 : (_props$theme24$imageS = _props$theme24.imageSlider) === null || _props$theme24$imageS === void 0 ? void 0 : _props$theme24$imageS.textColorLight, ")");
  } else if ((props === null || props === void 0 ? void 0 : (_props$imageSliderDat17 = props.imageSliderData) === null || _props$imageSliderDat17 === void 0 ? void 0 : _props$imageSliderDat17.fontColor) === fontColor.dark) {
    var _props$theme25, _props$theme25$imageS;
    return "var(--sc-image-slider--text-color-dark, ".concat((_props$theme25 = props.theme) === null || _props$theme25 === void 0 ? void 0 : (_props$theme25$imageS = _props$theme25.imageSlider) === null || _props$theme25$imageS === void 0 ? void 0 : _props$theme25$imageS.textColorDark, ")");
  }
}, function (props) {
  var _props$imageSliderDat18, _props$imageSliderDat19, _props$imageSliderDat20;
  if ((props === null || props === void 0 ? void 0 : (_props$imageSliderDat18 = props.imageSliderData) === null || _props$imageSliderDat18 === void 0 ? void 0 : _props$imageSliderDat18.fontColor) === null) {
    var _props$theme26, _props$theme26$imageS, _props$theme27, _props$theme27$imageS;
    return isBgColorStyleLight(backgroundStyles) ? "var(--sc-image-slider--text-color-dark, ".concat((_props$theme26 = props.theme) === null || _props$theme26 === void 0 ? void 0 : (_props$theme26$imageS = _props$theme26.imageSlider) === null || _props$theme26$imageS === void 0 ? void 0 : _props$theme26$imageS.textColorDark, ")") : "var(--sc-image-slider--text-color-light, ".concat((_props$theme27 = props.theme) === null || _props$theme27 === void 0 ? void 0 : (_props$theme27$imageS = _props$theme27.imageSlider) === null || _props$theme27$imageS === void 0 ? void 0 : _props$theme27$imageS.textColorLight, ")");
  }
  if ((props === null || props === void 0 ? void 0 : (_props$imageSliderDat19 = props.imageSliderData) === null || _props$imageSliderDat19 === void 0 ? void 0 : _props$imageSliderDat19.fontColor) === fontColor.light) {
    var _props$theme28, _props$theme28$imageS;
    return "var(--sc-image-slider--text-color-light, ".concat((_props$theme28 = props.theme) === null || _props$theme28 === void 0 ? void 0 : (_props$theme28$imageS = _props$theme28.imageSlider) === null || _props$theme28$imageS === void 0 ? void 0 : _props$theme28$imageS.textColorLight, ")");
  } else if ((props === null || props === void 0 ? void 0 : (_props$imageSliderDat20 = props.imageSliderData) === null || _props$imageSliderDat20 === void 0 ? void 0 : _props$imageSliderDat20.fontColor) === fontColor.dark) {
    var _props$theme29, _props$theme29$imageS;
    return "var(--sc-image-slider--text-color-dark, ".concat((_props$theme29 = props.theme) === null || _props$theme29 === void 0 ? void 0 : (_props$theme29$imageS = _props$theme29.imageSlider) === null || _props$theme29$imageS === void 0 ? void 0 : _props$theme29$imageS.textColorDark, ")");
  }
}, function (props) {
  var _props$imageSliderDat21, _props$imageSliderDat22, _props$imageSliderDat23;
  if ((props === null || props === void 0 ? void 0 : (_props$imageSliderDat21 = props.imageSliderData) === null || _props$imageSliderDat21 === void 0 ? void 0 : _props$imageSliderDat21.fontColor) === null) {
    var _props$theme30, _props$theme30$imageS, _props$theme31, _props$theme31$imageS;
    return isBgColorStyleLight(backgroundStyles) ? "var(--sc-image-slider--text-color-dark, ".concat((_props$theme30 = props.theme) === null || _props$theme30 === void 0 ? void 0 : (_props$theme30$imageS = _props$theme30.imageSlider) === null || _props$theme30$imageS === void 0 ? void 0 : _props$theme30$imageS.textColorDark, ")") : "var(--sc-image-slider--text-color-light, ".concat((_props$theme31 = props.theme) === null || _props$theme31 === void 0 ? void 0 : (_props$theme31$imageS = _props$theme31.imageSlider) === null || _props$theme31$imageS === void 0 ? void 0 : _props$theme31$imageS.textColorLight, ")");
  }
  if ((props === null || props === void 0 ? void 0 : (_props$imageSliderDat22 = props.imageSliderData) === null || _props$imageSliderDat22 === void 0 ? void 0 : _props$imageSliderDat22.fontColor) === fontColor.light) {
    var _props$theme32, _props$theme32$imageS;
    return "var(--sc-image-slider--text-color-light, ".concat((_props$theme32 = props.theme) === null || _props$theme32 === void 0 ? void 0 : (_props$theme32$imageS = _props$theme32.imageSlider) === null || _props$theme32$imageS === void 0 ? void 0 : _props$theme32$imageS.textColorLight, ")");
  } else if ((props === null || props === void 0 ? void 0 : (_props$imageSliderDat23 = props.imageSliderData) === null || _props$imageSliderDat23 === void 0 ? void 0 : _props$imageSliderDat23.fontColor) === fontColor.dark) {
    var _props$theme33, _props$theme33$imageS;
    return "var(--sc-image-slider--text-color-dark, ".concat((_props$theme33 = props.theme) === null || _props$theme33 === void 0 ? void 0 : (_props$theme33$imageS = _props$theme33.imageSlider) === null || _props$theme33$imageS === void 0 ? void 0 : _props$theme33$imageS.textColorDark, ")");
  }
});
export default styles;