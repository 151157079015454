import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import { getBackgroundStyle, isBgColorStyleLight, setNonCtaAnchorStyling, replaceSecondaryAnchorWithAltSecondaryAnchor } from '../../../utils/utilityFunction';
import textures from '../../../themes/textures';
import fontColor from '../../../constants/globalConstants';
var backgroundStyles = '';
var styles = css(["margin:0;padding:var(--sc-spacing-7,40px) var(--sc-spacing-4,20px);text-align:center;", ";*{box-sizing:border-box;}.showAllTilesContainer{width:100%;max-width:1070px;flex-wrap:wrap;justify-content:space-between;> a:nth-child(4){margin-left:var(--sc-spacing-5,25px);@media (min-width:", "){margin-left:0;}}> a:nth-child(2){margin-left:var(--sc-spacing-5,25px);margin-right:var(--sc-spacing-5,25px);@media (min-width:", "){margin-left:0;margin-right:0;}}a{max-width:226px;width:100%;margin:0;div{height:248px;width:100%;picture{height:100%;width:100%;img{height:100%;width:100%;object-fit:cover;}}}@media (min-width:", "){max-width:248px;}}}.gallery-heading{text-align:center;display:block;margin:0 auto var(--sc-spacing-4,20px);color:", ";@media (min-width:", "){margin-bottom:var(--sc-spacing-4,20px);}}.gallery-description{margin:0 auto var(--sc-spacing-5,30px);text-align:center;ul{li{display:block;p{display:list-item;list-style-position:inside;}}}ol{li{display:block;p{display:list-item;list-style-position:inside;}}}h2,h3,h4,ul,ol,p{color:", ";}a:not(.sg-btn-cpnt){", "}@media (min-width:", ") and (max-width:", "){padding:0 var(--sc-spacing-16,114px);}}.gallery-top-cta{margin-bottom:30px;.sg-btn-cpnt.secondary-anchor{", " ", "}@media (max-width:", "){margin-bottom:0;}}.slick-track > .slick-slide > div{display:flex;align-items:center;justify-content:center;flex-wrap:nowrap;@media (max-width:", "){pointer-events:none;}@media (min-width:", "){section{margin:0 var(--sc-spacing-5,30px) 0 0;height:auto;width:auto;}a:last-of-type{section{margin-right:0;}}}}@media (min-width:", "){padding:var(--sc-spacing-12,80px) 0;}"], function (props) {
  if (props.miniGalleryData) {
    var _props$theme, _props$theme$gallery, _props$theme2;
    var overrideBackground = props.miniGalleryData.overrideBackground;
    var overrideId = overrideBackground ? overrideBackground.backgroundId : '';
    var backgroundImageURLs = overrideBackground && overrideBackground.imageBackground ? {
      desktopImage: overrideBackground.imageBackground && overrideBackground.imageBackground.url,
      mobileImage: overrideBackground.imageBackground && overrideBackground.imageBackground.url
    } : '';
    backgroundStyles = getBackgroundStyle(overrideId, backgroundImageURLs, overrideBackground && overrideBackground.colorBackground || ((_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : (_props$theme$gallery = _props$theme.gallery) === null || _props$theme$gallery === void 0 ? void 0 : _props$theme$gallery.bgColor), (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.palette, textures);
    return backgroundStyles;
  }
}, breakPoints.desktop, breakPoints.desktop, breakPoints.desktopM, function (props) {
  var _props$miniGalleryDat, _props$miniGalleryDat2, _props$miniGalleryDat3;
  if ((props === null || props === void 0 ? void 0 : (_props$miniGalleryDat = props.miniGalleryData) === null || _props$miniGalleryDat === void 0 ? void 0 : _props$miniGalleryDat.fontColor) === null) {
    var _props$theme3, _props$theme3$gallery, _props$theme4, _props$theme4$gallery;
    return isBgColorStyleLight(backgroundStyles) ? "var(--sc-gallery--text-color-dark, ".concat((_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : (_props$theme3$gallery = _props$theme3.gallery) === null || _props$theme3$gallery === void 0 ? void 0 : _props$theme3$gallery.textColorDark, ")") : "var(--sc-gallery--text-color-light, ".concat((_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : (_props$theme4$gallery = _props$theme4.gallery) === null || _props$theme4$gallery === void 0 ? void 0 : _props$theme4$gallery.textColorLight, ")");
  }
  if ((props === null || props === void 0 ? void 0 : (_props$miniGalleryDat2 = props.miniGalleryData) === null || _props$miniGalleryDat2 === void 0 ? void 0 : _props$miniGalleryDat2.fontColor) === fontColor.light) {
    var _props$theme5, _props$theme5$gallery;
    return "var(--sc-gallery--text-color-light, ".concat((_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : (_props$theme5$gallery = _props$theme5.gallery) === null || _props$theme5$gallery === void 0 ? void 0 : _props$theme5$gallery.textColorLight, ")");
  } else if ((props === null || props === void 0 ? void 0 : (_props$miniGalleryDat3 = props.miniGalleryData) === null || _props$miniGalleryDat3 === void 0 ? void 0 : _props$miniGalleryDat3.fontColor) === fontColor.dark) {
    var _props$theme6, _props$theme6$gallery;
    return "var(--sc-gallery--text-color-dark, ".concat((_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : (_props$theme6$gallery = _props$theme6.gallery) === null || _props$theme6$gallery === void 0 ? void 0 : _props$theme6$gallery.textColorDark, ")");
  }
}, breakPoints.desktop, function (props) {
  var _props$miniGalleryDat4, _props$miniGalleryDat5, _props$miniGalleryDat6;
  if ((props === null || props === void 0 ? void 0 : (_props$miniGalleryDat4 = props.miniGalleryData) === null || _props$miniGalleryDat4 === void 0 ? void 0 : _props$miniGalleryDat4.fontColor) === null) {
    var _props$theme7, _props$theme7$gallery, _props$theme8, _props$theme8$gallery;
    return isBgColorStyleLight(backgroundStyles) ? "var(--sc-gallery--text-color-dark, ".concat((_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : (_props$theme7$gallery = _props$theme7.gallery) === null || _props$theme7$gallery === void 0 ? void 0 : _props$theme7$gallery.textColorDark, ")") : "var(--sc-gallery--text-color-light, ".concat((_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : (_props$theme8$gallery = _props$theme8.gallery) === null || _props$theme8$gallery === void 0 ? void 0 : _props$theme8$gallery.textColorLight, ")");
  }
  if ((props === null || props === void 0 ? void 0 : (_props$miniGalleryDat5 = props.miniGalleryData) === null || _props$miniGalleryDat5 === void 0 ? void 0 : _props$miniGalleryDat5.fontColor) === fontColor.light) {
    var _props$theme9, _props$theme9$gallery;
    return "var(--sc-gallery--text-color-light, ".concat((_props$theme9 = props.theme) === null || _props$theme9 === void 0 ? void 0 : (_props$theme9$gallery = _props$theme9.gallery) === null || _props$theme9$gallery === void 0 ? void 0 : _props$theme9$gallery.textColorLight, ")");
  } else if ((props === null || props === void 0 ? void 0 : (_props$miniGalleryDat6 = props.miniGalleryData) === null || _props$miniGalleryDat6 === void 0 ? void 0 : _props$miniGalleryDat6.fontColor) === fontColor.dark) {
    var _props$theme10, _props$theme10$galler;
    return "var(--sc-gallery--text-color-dark, ".concat((_props$theme10 = props.theme) === null || _props$theme10 === void 0 ? void 0 : (_props$theme10$galler = _props$theme10.gallery) === null || _props$theme10$galler === void 0 ? void 0 : _props$theme10$galler.textColorDark, ")");
  }
}, function (props) {
  var _props$miniGalleryDat7, _props$miniGalleryDat8, _props$theme11;
  return (props === null || props === void 0 ? void 0 : (_props$miniGalleryDat7 = props.miniGalleryData) === null || _props$miniGalleryDat7 === void 0 ? void 0 : _props$miniGalleryDat7.fontColor) === fontColor.light || ((_props$miniGalleryDat8 = props.miniGalleryData) === null || _props$miniGalleryDat8 === void 0 ? void 0 : _props$miniGalleryDat8.fontColor) === null && !isBgColorStyleLight(backgroundStyles) ? setNonCtaAnchorStyling((_props$theme11 = props.theme) === null || _props$theme11 === void 0 ? void 0 : _props$theme11.gallery) : '';
}, breakPoints.tabletP, breakPoints.tabletL, function (props) {
  var _props$theme12, _props$theme12$button, _props$miniGalleryDat9, _props$miniGalleryDat10, _props$miniGalleryDat11, _props$miniGalleryDat12, _props$theme13;
  return (_props$theme12 = props.theme) !== null && _props$theme12 !== void 0 && (_props$theme12$button = _props$theme12.button) !== null && _props$theme12$button !== void 0 && _props$theme12$button.hasAlternateDarkSecondaryAnchor && ((_props$miniGalleryDat9 = props.miniGalleryData) === null || _props$miniGalleryDat9 === void 0 ? void 0 : _props$miniGalleryDat9.fontColor) !== fontColor.light && (((_props$miniGalleryDat10 = props.miniGalleryData) === null || _props$miniGalleryDat10 === void 0 ? void 0 : _props$miniGalleryDat10.fontColor) === fontColor.dark || (((_props$miniGalleryDat11 = props.miniGalleryData) === null || _props$miniGalleryDat11 === void 0 ? void 0 : _props$miniGalleryDat11.fontColor) === null || ((_props$miniGalleryDat12 = props.miniGalleryData) === null || _props$miniGalleryDat12 === void 0 ? void 0 : _props$miniGalleryDat12.fontColor) === undefined) && isBgColorStyleLight(backgroundStyles)) ? replaceSecondaryAnchorWithAltSecondaryAnchor((_props$theme13 = props.theme) === null || _props$theme13 === void 0 ? void 0 : _props$theme13.button) : '';
}, function (props) {
  var _props$theme14, _props$theme14$button, _props$miniGalleryDat13, _props$miniGalleryDat14, _props$theme15;
  return (_props$theme14 = props.theme) !== null && _props$theme14 !== void 0 && (_props$theme14$button = _props$theme14.button) !== null && _props$theme14$button !== void 0 && _props$theme14$button.hasAlternateLightSecondaryAnchor && ((props === null || props === void 0 ? void 0 : (_props$miniGalleryDat13 = props.miniGalleryData) === null || _props$miniGalleryDat13 === void 0 ? void 0 : _props$miniGalleryDat13.fontColor) === fontColor.light || ((_props$miniGalleryDat14 = props.miniGalleryData) === null || _props$miniGalleryDat14 === void 0 ? void 0 : _props$miniGalleryDat14.fontColor) === null && !isBgColorStyleLight(backgroundStyles)) ? replaceSecondaryAnchorWithAltSecondaryAnchor((_props$theme15 = props.theme) === null || _props$theme15 === void 0 ? void 0 : _props$theme15.button, false, true) : '';
}, breakPoints.mobile, breakPoints.tabletL, breakPoints.desktop, breakPoints.desktop);
export default styles;