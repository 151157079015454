export var imageRenditions = {
  desktopL: {
    h: '76',
    w: '76'
  },
  desktop: {
    h: '76',
    w: '76'
  },
  tabletL: {
    h: '76',
    w: '76'
  },
  tabletP: {
    h: '76',
    w: '76'
  },
  mobile: {
    h: '76',
    w: '76'
  }
};
export var calculateCharLimit = function calculateCharLimit(windowWidth) {
  switch (true) {
    case windowWidth > charLimits.desktopS.w:
      return charLimits.desktopS.limit;
    case windowWidth > charLimits.desktop.w:
      return Math.min(Math.floor(windowWidth * 0.35), charLimits.desktop.limit);
    case windowWidth > charLimits.tablet.w:
      return Math.min(Math.floor(windowWidth * 0.38), charLimits.tablet.limit);
    case windowWidth > charLimits.mobile.w:
      return Math.max(Math.floor(windowWidth * 0.5), charLimits.mobile.limit);
    default:
      return 180;
  }
};
var charLimits = {
  mobile: {
    w: 349,
    limit: 220
  },
  tablet: {
    w: 767,
    limit: 400
  },
  desktop: {
    w: 1023,
    limit: 375
  },
  desktopS: {
    w: 1280,
    limit: 375
  }
};