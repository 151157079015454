export var imageRenditions = {
  desktopL: {
    w: '276',
    h: '246'
  },
  desktop: {
    w: '236',
    h: '210'
  },
  tabletL: {
    w: '226',
    h: '200'
  },
  tabletP: {
    w: '320',
    h: '384'
  },
  mobile: {
    w: '375',
    h: '334'
  }
};