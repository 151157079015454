export var imageRenditions = {
  desktopL: {
    w: '258',
    h: '258'
  },
  desktop: {
    w: '220',
    h: '220'
  },
  tabletL: {
    w: '220',
    h: '220'
  },
  tabletP: {
    w: '220',
    h: '220'
  },
  mobile: {
    w: '220',
    h: '220'
  }
};
export var imageRenditionsVariationB = {
  desktopL: {
    w: '232',
    h: '255'
  },
  desktop: {
    w: '198',
    h: '218'
  },
  tabletL: {
    w: '178',
    h: '196'
  },
  tabletP: {
    w: '280',
    h: '308'
  },
  mobile: {
    w: '328',
    h: '360'
  }
};
export var scrollableCarouselDesktopSettings = function scrollableCarouselDesktopSettings(staffBladeData, appliedBladeClass) {
  var numberOfCardsPerSlide = staffBladeData.numberOfCardsPerSlide && parseInt(staffBladeData.numberOfCardsPerSlide) || 1;
  var tiles = staffBladeData && staffBladeData.staffCardCollection && staffBladeData.staffCardCollection.items ? staffBladeData.staffCardCollection.items.length : 0;
  numberOfCardsPerSlide = numberOfCardsPerSlide > 3 ? 3 : numberOfCardsPerSlide;
  if (tiles <= numberOfCardsPerSlide) {
    numberOfCardsPerSlide = tiles - 1;
  }
  return {
    className: "".concat(appliedBladeClass, "-slider").concat(numberOfCardsPerSlide == 3 ? ' three-card-center' : ''),
    centerMode: numberOfCardsPerSlide == 3,
    infinite: false,
    slidesToShow: numberOfCardsPerSlide,
    autoplaySpeed: staffBladeData.timePerSlide && parseInt(staffBladeData.timePerSlide),
    autoplay: staffBladeData.timePerSlide ? true : false,
    slidesToScroll: 2,
    speed: 500
  };
};