export var imageRenditions = {
  desktopL: {
    h: '582',
    w: '916'
  },
  desktop: {
    h: '480',
    w: '782'
  },
  tabletL: {
    h: '480',
    w: '584'
  },
  tabletP: {
    h: '442',
    w: '540'
  },
  mobile: {
    h: '200',
    w: '320'
  }
};
export var videoImageRenditions = {
  desktopL: {
    h: '492',
    w: '830'
  },
  desktopS: {
    h: '47',
    w: '78'
  },
  desktop: {
    h: '476',
    w: '800'
  },
  tabletL: {
    h: '476',
    w: '687'
  },
  tabletP: {
    h: '442',
    w: '740'
  },
  mobile: {
    h: '425',
    w: '712'
  }
};