import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import fontColor from '../../../constants/globalConstants';
import textures from '../../../themes/textures';
import { getBackgroundStyle, isBgColorStyleLight, setNonCtaAnchorStyling, replacePrimaryAnchorWithLightPrimaryAnchor } from '../../../utils/utilityFunction';
var backgroundStyles = '';
var styles = css(["", ";padding:var(--sc-spacing-7,40px) var(--sc-spacing-4,20px);background-size:cover;.promo-banner{text-align:center;@media (min-width:", ") and (max-width:", "){padding:0 var(--sc-spacing-13,94px);}}p,h2,h3,h4,ul,ol,.heading_four{color:", ";}ul{li{display:block;p{padding-left:0;display:list-item;list-style-type:'\u2022   ';list-style-position:inside;}}}ol{li{display:block;padding-left:0;p{display:list-item;list-style-position:inside;}}}p{margin:0 auto var(--sc-spacing-4,20px);&:last-child{margin-bottom:var(--sc-promo-banner--last-paragraph-bottom-margin,", ");}}.promo-link{margin-top:var(--sc-spacing-5,30px);margin-bottom:var(--sc-spacing-3,10px);}h2,.heading_four,a{margin:0 auto var(--sc-spacing-4,20px);@media (max-width:", "){margin:0 auto var(--sc-spacing-3,10px);}}a:not(.sg-btn-cpnt){", " display:inline;}.sg-btn-cpnt.primary-anchor{", "}@media (min-width:", "){padding:var(--sc-spacing-12,80px) var(--sc-spacing-16,114px);}"], function (props) {
  if (props.promoData) {
    var _props$theme, _props$theme$promoBan, _props$theme2;
    var _props$promoData = props.promoData,
      backgroundImage = _props$promoData.backgroundImage,
      overrideBackground = _props$promoData.overrideBackground;
    var backgroundImageURLs = backgroundImage ? {
      desktopImage: backgroundImage.image && backgroundImage.image.url,
      mobileImage: backgroundImage.mobileImage && backgroundImage.mobileImage.url
    } : overrideBackground && overrideBackground.imageBackground ? {
      desktopImage: overrideBackground.imageBackground && overrideBackground.imageBackground.url,
      mobileImage: overrideBackground.imageBackground && overrideBackground.imageBackground.url
    } : '';
    var overrideId = overrideBackground ? overrideBackground.backgroundId : '';
    backgroundStyles = getBackgroundStyle(overrideId, backgroundImageURLs, overrideBackground && overrideBackground.colorBackground || ((_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : (_props$theme$promoBan = _props$theme.promoBanner) === null || _props$theme$promoBan === void 0 ? void 0 : _props$theme$promoBan.bgColor), (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : _props$theme2.palette, textures);
    return backgroundStyles;
  }
}, breakPoints.tabletP, breakPoints.tabletL, function (props) {
  var _props$promoData2, _props$promoData3, _props$promoData4;
  if ((props === null || props === void 0 ? void 0 : (_props$promoData2 = props.promoData) === null || _props$promoData2 === void 0 ? void 0 : _props$promoData2.fontColor) === null) {
    var _props$theme3, _props$theme3$promoBa, _props$theme4, _props$theme4$promoBa;
    return isBgColorStyleLight(backgroundStyles) ? "var(--sc-promo-banner--text-color-dark, ".concat((_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : (_props$theme3$promoBa = _props$theme3.promoBanner) === null || _props$theme3$promoBa === void 0 ? void 0 : _props$theme3$promoBa.textColorDark, ")") : "var(--sc-promo-banner--text-color-light, ".concat((_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : (_props$theme4$promoBa = _props$theme4.promoBanner) === null || _props$theme4$promoBa === void 0 ? void 0 : _props$theme4$promoBa.textColorLight, ")");
  }
  if ((props === null || props === void 0 ? void 0 : (_props$promoData3 = props.promoData) === null || _props$promoData3 === void 0 ? void 0 : _props$promoData3.fontColor) === fontColor.light) {
    var _props$theme5, _props$theme5$promoBa;
    return "var(--sc-promo-banner--text-color-light, ".concat((_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : (_props$theme5$promoBa = _props$theme5.promoBanner) === null || _props$theme5$promoBa === void 0 ? void 0 : _props$theme5$promoBa.textColorLight, ")");
  } else if ((props === null || props === void 0 ? void 0 : (_props$promoData4 = props.promoData) === null || _props$promoData4 === void 0 ? void 0 : _props$promoData4.fontColor) === fontColor.dark) {
    var _props$theme6, _props$theme6$promoBa;
    return "var(--sc-promo-banner--text-color-dark, ".concat((_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : (_props$theme6$promoBa = _props$theme6.promoBanner) === null || _props$theme6$promoBa === void 0 ? void 0 : _props$theme6$promoBa.textColorDark, ")");
  }
}, function (props) {
  var _props$theme7, _props$theme7$promoBa;
  return ((_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : (_props$theme7$promoBa = _props$theme7.promoBanner) === null || _props$theme7$promoBa === void 0 ? void 0 : _props$theme7$promoBa.lastParagraphBottomMargin) || '0';
}, breakPoints.mobile, function (props) {
  var _props$promoData5, _props$promoData6, _props$theme8;
  return ((_props$promoData5 = props.promoData) === null || _props$promoData5 === void 0 ? void 0 : _props$promoData5.fontColor) === fontColor.light || ((_props$promoData6 = props.promoData) === null || _props$promoData6 === void 0 ? void 0 : _props$promoData6.fontColor) === null && !isBgColorStyleLight(backgroundStyles) ? setNonCtaAnchorStyling((_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : _props$theme8.promoBanner) : '';
}, function (props) {
  var _props$theme9, _props$theme9$button, _props$promoData7, _props$theme10, _props$theme10$button, _props$promoData8, _props$theme11;
  return (_props$theme9 = props.theme) !== null && _props$theme9 !== void 0 && (_props$theme9$button = _props$theme9.button) !== null && _props$theme9$button !== void 0 && _props$theme9$button.hasAlternatePrimaryAnchor && (((_props$promoData7 = props.promoData) === null || _props$promoData7 === void 0 ? void 0 : _props$promoData7.fontColor) === fontColor.light && (_props$theme10 = props.theme) !== null && _props$theme10 !== void 0 && (_props$theme10$button = _props$theme10.button) !== null && _props$theme10$button !== void 0 && _props$theme10$button.linkColorLightPrimary || ((_props$promoData8 = props.promoData) === null || _props$promoData8 === void 0 ? void 0 : _props$promoData8.fontColor) === null && !isBgColorStyleLight(backgroundStyles)) ? replacePrimaryAnchorWithLightPrimaryAnchor((_props$theme11 = props.theme) === null || _props$theme11 === void 0 ? void 0 : _props$theme11.button) : "";
}, breakPoints.desktop);
export default styles;