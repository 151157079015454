import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
/**
 * Represents Image Slider Component.
 * @author Harmeet
 */import React from 'react';
import { imageRenditions, videoImageRenditions } from './config';
import styled from 'styled-components';
import styles from './ImageSlider.style';
import analyticsConstants from '../../../constants/analyticsConstants';
import ImageSliderTextCard from '../../molecules/ImageSliderTextCard';
import CarouselArrow from '../../atoms/CarouselArrow';
var ImageSliderBlock = styled.section.withConfig({
  componentId: "sc-1cjeaqo-0"
})(["", ";"], styles);
var ImageSlider = function ImageSlider(_ref) {
  var imageSliderData = _ref.imageSliderData,
    currentSlide = _ref.currentSlide,
    slideCount = _ref.slideCount,
    className = _ref.className;
  if (!imageSliderData) {
    return null;
  }
  var text = imageSliderData && imageSliderData.bodyText && imageSliderData.bodyText.json;
  var images = imageSliderData && imageSliderData.imagesCollection && imageSliderData.imagesCollection.items;
  var dto = {
    headline: imageSliderData.headline,
    subPara: imageSliderData.subheadline,
    video: imageSliderData.video,
    bodyText: text,
    carouselImages: images,
    overrideBackground: imageSliderData.overrideBackground,
    srcSetImg: imageSliderData.video ? videoImageRenditions : imageRenditions,
    carouselSettings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      speed: 500,
      infinite: true,
      dots: true,
      prevArrow: __jsx(CarouselArrow, {
        type: "previous",
        analyticsType: analyticsConstants.imageSlider.analyticsType.ARROWS,
        analyticsValue: analyticsConstants.imageSlider.analyticsValue.PREV_ARROW
      }),
      nextArrow: __jsx(CarouselArrow, {
        type: "next",
        analyticsType: analyticsConstants.imageSlider.analyticsType.ARROWS,
        analyticsValue: analyticsConstants.imageSlider.analyticsValue.NEXT_ARROW
      })
    }
  };
  dto.videoSetting = {
    autoplay: '0',
    controls: '0',
    loop: '1',
    fs: '0',
    mute: '0'
  };
  var anchorLink = imageSliderData && (imageSliderData === null || imageSliderData === void 0 ? void 0 : imageSliderData.anchorLink) || '';
  return __jsx(ImageSliderBlock, {
    className: "nva-image-slider ".concat(className),
    id: anchorLink
  }, __jsx("div", {
    className: "nva-image-slider__wrapper organism container image-slider"
  }, __jsx(ImageSliderTextCard, _extends({
    className: "nva-image-slider__image-slider-text-card"
  }, dto))));
};
ImageSlider.defaultProps = {
  className: ''
};
export default styled(ImageSlider).withConfig({
  componentId: "sc-1cjeaqo-1"
})(["", ";"], styles);