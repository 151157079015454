import { css } from 'styled-components';
import textures from '../../../themes/textures';
import { getBackgroundStyle } from '../../../utils/utilityFunction';
import breakPoints from '../../../themes/breakpoints';
var backgroundStyles = '';
var styles = css([".mobile-variation-full &{position:absolute;top:0;left:0;height:100%;width:100%;&.light{background-color:rgba(255,255,255,0.1);}&.dark{background-color:rgba(0,0,0,0.1);}&.bg-color-override.dark,&.bg-color-override.light{@media (max-width:", "){", ";}}}"], breakPoints.mobile, function (props) {
  if (props.background) {
    var background = props.background;
    var backgroundImageURLs = background && background.imageBackground ? {
      desktopImage: background.imageBackground && background.imageBackground.url,
      mobileImage: background.imageBackground && background.imageBackground.url
    } : '';
    var overrideId = background ? background.backgroundId : '';
    backgroundStyles = getBackgroundStyle(overrideId, backgroundImageURLs, background && background.colorBackground || props.theme.accordion.bodyBackgroundColor, props.theme.palette, textures);
    return backgroundStyles;
  }
});
export default styles;