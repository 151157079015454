import _defineProperty from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import React from 'react';
import { withTheme } from 'styled-components';
import DefaultNext from '../../../static/images/icons/Next';
import DefaultPrev from '../../../static/images/icons/Prev';
import styled from 'styled-components';
import styles from './CarouselArrow.style';
var CarouselArrow = function CarouselArrow(_ref) {
  var currentSlide = _ref.currentSlide,
    slideCount = _ref.slideCount,
    className = _ref.className,
    style = _ref.style,
    onClick = _ref.onClick,
    type = _ref.type,
    analyticsType = _ref.analyticsType,
    analyticsValue = _ref.analyticsValue,
    theme = _ref.theme;
  var Next = theme !== null && theme !== void 0 && theme.carousel.iconNext ? theme.carousel.iconNext : DefaultNext;
  var Prev = theme !== null && theme !== void 0 && theme.carousel.iconPrev ? theme.carousel.iconPrev : DefaultPrev;
  return __jsx("button", {
    style: _objectSpread({}, style),
    className: "nva-carousel-arrow ".concat(className, " carousel-arrow"),
    currentslide: currentSlide,
    slidecount: slideCount,
    onClick: onClick,
    "data-analytics-type": analyticsType,
    "data-analytics-value": analyticsValue,
    "data-analytics-variation": "",
    "aria-label": type === 'previous' ? 'previous' : 'next'
  }, type === 'previous' ? __jsx(Prev, null) : __jsx(Next, null));
};
CarouselArrow.defaultProps = {
  className: '',
  analyticsType: '',
  analyticsValue: ''
};
var styledCarouselArrow = styled(CarouselArrow).withConfig({
  componentId: "sc-x2sqo7-0"
})(["", ";"], styles);
export default withTheme(styledCarouselArrow);
export { CarouselArrow as CarouselArrowVanilla };