var __jsx = React.createElement;
import React from 'react';
import { IframeStyles } from './Reviews.style';
import styled from 'styled-components';
var IframeBlock = styled.section.withConfig({
  componentId: "sc-1428lgm-0"
})(["", ""], IframeStyles);
var IframeModal = function IframeModal(_ref) {
  var showPopUp = _ref.showPopUp,
    setShowPopUp = _ref.setShowPopUp,
    writeReviewCta = _ref.writeReviewCta,
    className = _ref.className;
  if (!showPopUp) {
    return null;
  }
  return __jsx(IframeBlock, {
    className: "nva-review__modal ".concat(className)
  }, __jsx("div", {
    className: "iframe_modal"
  }, __jsx("button", {
    className: "close",
    onClick: function onClick() {
      return setShowPopUp(false);
    }
  }, "\xD7"), __jsx("iframe", {
    width: "100%",
    height: "100%",
    src: writeReviewCta.url || '/'
  })), __jsx("div", {
    className: "iframe_bg"
  }));
};
IframeModal.defaultProps = {
  className: ''
};
export default styled(IframeModal).withConfig({
  componentId: "sc-1428lgm-1"
})(["", ";"], IframeStyles);