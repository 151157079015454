export var imageRenditions = {
  desktopL: {
    w: '248',
    h: '248'
  },
  desktop: {
    w: '248',
    h: '248'
  },
  tabletL: {
    w: '226',
    h: '248'
  },
  tabletP: {
    w: '264',
    h: '264'
  },
  mobile: {
    w: '132',
    h: '132'
  }
};