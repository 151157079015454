import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "count"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './Star.style';
var StyleSection = styled.section.withConfig({
  componentId: "sc-9b2au6-0"
})(["", ";"], styles);
var Star = function Star(_ref) {
  var className = _ref.className,
    count = _ref.count,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx(StyleSection, {
    className: "nva-star ".concat(className)
  }, __jsx("div", {
    className: "nva-star__block stars_block"
  }, Array.from({
    length: count
  }, function (v, i) {
    return __jsx("img", {
      key: i,
      className: "nva-star__star-icon",
      src: "/svg/star.svg",
      alt: "star rating 5 out of 5",
      height: "20",
      width: "20",
      loading: "lazy",
      decoding: "async"
    });
  }), __jsx("p", null, count, " / 5 Stars")));
};
Star.defaultProps = {
  count: 5,
  className: ''
};
export default styled(Star).withConfig({
  componentId: "sc-9b2au6-1"
})(["", ";"], styles);
export { Star as StarVanilla };