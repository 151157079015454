import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
import { getImageRenditions, getImageRenditionsVariationC } from './HeroCarouselUtility';
var getSrcSetSetting = function getSrcSetSetting(heroCarouselData) {
  var mobileHeroVariation = heroCarouselData && heroCarouselData.mobileHeroVariation && heroCarouselData.mobileHeroVariation.toLowerCase() || 'split';
  var height = heroCarouselData && heroCarouselData.height && heroCarouselData.height.toLowerCase() || 'large';
  return {
    imageRenditions: heroCarouselData && heroCarouselData.variation.toLowerCase() === 'variation-c' ? getImageRenditionsVariationC(height, mobileHeroVariation) : getImageRenditions(height, mobileHeroVariation),
    quality: 72
  };
};
var styles = css(["background-color:var(--sc-carousel--hero-bg-color,", ");line-height:0;.video-container{pointer-events:none;&.variation-a,&.variation-b{iframe{margin-bottom:0;position:absolute;width:100%;height:calc(0.5625 * (100vw ", "));max-height:900px;z-index:0;top:50%;left:50%;transform:translate(-50%,-50%) scale(1);@media (min-width:", "){transform:translate(-50%,-50%) scale(1);max-height:calc(0.5625 * ", ");height:calc(0.5625 * ", ");}@media (min-width:", "){transform:translate(-50%,-50%) scale(1);height:calc(0.5625 * (", " ", "));max-height:calc(0.5625 * ", ");}@media (min-width:", "){transform:translate(-50%,-50%) scale(1);max-height:900px;height:calc(0.5625 * (100vw ", "));}}}&.variation-c{iframe{margin-bottom:0;position:absolute;width:calc(100vw + 2px);height:calc(0.5625 * (100vw ", "));max-height:900px;z-index:0;top:50%;left:50%;transform:translate(-50%,-50%) scale(1);@media (min-width:", "){transform:translate(-50%,-50%) scale(1);height:calc(0.5625 * ", ");}@media (min-width:", "){transform:translate(-50%,-50%) scale(1);height:calc(0.5625 * ", ");}}}}.card-header{margin-bottom:var(--sc-spacing-4,20px);}.hero-carousel{.variation-a,.variation-b{@media (min-width:", "){&.small{.slick-arrow{top:156px;}}&.medium{.slick-arrow{top:208px;}}}}}.slick-arrow{display:block !important;@media (min-width:", "){display:block !important;}@media (max-width:", ") and (min-width:", "){top:", "px;}@media (max-width:", "){top:", "px;}}.slick-dots{width:100%;padding-bottom:0;button{", " ", " @media (min-width:", "){", "}}.slick-dots-custom{", " ", " @media (min-width:", "){", "}}.slick-active{button{", " @media (min-width:", "){", "}}.slick-dots-custom{", " @media (min-width:", "){", "}}}}@media (min-width:", "){.slick-dots{padding-bottom:0;}}"], function (props) {
  var _props$theme, _props$theme$carousel;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : (_props$theme$carousel = _props$theme.carousel) === null || _props$theme$carousel === void 0 ? void 0 : _props$theme$carousel.bgColor;
}, function (props) {
  return props.scrollbarWidth ? "- ".concat(props.scrollbarWidth, "px") : '';
}, breakPoints.tabletP, breakPoints.desktop, breakPoints.desktopS, breakPoints.desktop, breakPoints.desktopS, function (props) {
  return props.scrollbarWidth ? "- ".concat(props.scrollbarWidth, "px") : '';
}, breakPoints.desktopS, breakPoints.desktopS, function (props) {
  return props.scrollbarWidth ? "- ".concat(props.scrollbarWidth, "px") : '';
}, function (props) {
  return props.scrollbarWidth ? "- ".concat(props.scrollbarWidth, "px") : '';
}, breakPoints.tabletP, breakPoints.desktop, breakPoints.desktop, breakPoints.desktopS, breakPoints.desktop, breakPoints.desktop, breakPoints.tabletL, breakPoints.mobile, function (props) {
  return getSrcSetSetting(props.heroCarouselData).imageRenditions.tabletP.h / 2 || 250;
}, breakPoints.mobile, function (props) {
  return getSrcSetSetting(props.heroCarouselData).imageRenditions.mobile.h / 2 || 250;
}, function (props) {
  var _props$theme2, _props$theme2$carouse, _props$theme3, _props$theme3$carouse;
  return (_props$theme2 = props.theme) !== null && _props$theme2 !== void 0 && (_props$theme2$carouse = _props$theme2.carousel) !== null && _props$theme2$carouse !== void 0 && _props$theme2$carouse.nvaHeroPaginationBorderColor ? "border-color: ".concat((_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : (_props$theme3$carouse = _props$theme3.carousel) === null || _props$theme3$carouse === void 0 ? void 0 : _props$theme3$carouse.nvaHeroPaginationBorderColor, ";") : '';
}, function (props) {
  var _props$theme4, _props$theme4$carouse, _props$theme5, _props$theme5$carouse;
  return (_props$theme4 = props.theme) !== null && _props$theme4 !== void 0 && (_props$theme4$carouse = _props$theme4.carousel) !== null && _props$theme4$carouse !== void 0 && _props$theme4$carouse.nvaHeroPaginationInactiveColor ? "background-color: ".concat((_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : (_props$theme5$carouse = _props$theme5.carousel) === null || _props$theme5$carouse === void 0 ? void 0 : _props$theme5$carouse.nvaHeroPaginationInactiveColor, ";") : '';
}, breakPoints.desktop, function (props) {
  var _props$theme6, _props$theme6$carouse, _props$theme7, _props$theme7$carouse;
  return (_props$theme6 = props.theme) !== null && _props$theme6 !== void 0 && (_props$theme6$carouse = _props$theme6.carousel) !== null && _props$theme6$carouse !== void 0 && _props$theme6$carouse.nvaHeroPaginationInactiveColor ? "background-color: ".concat((_props$theme7 = props.theme) === null || _props$theme7 === void 0 ? void 0 : (_props$theme7$carouse = _props$theme7.carousel) === null || _props$theme7$carouse === void 0 ? void 0 : _props$theme7$carouse.nvaHeroPaginationInactiveColor, ";") : '';
}, function (props) {
  var _props$theme8, _props$theme8$carouse, _props$theme9, _props$theme9$carouse;
  return (_props$theme8 = props.theme) !== null && _props$theme8 !== void 0 && (_props$theme8$carouse = _props$theme8.carousel) !== null && _props$theme8$carouse !== void 0 && _props$theme8$carouse.nvaHeroPaginationBorderColor ? "border-color: ".concat((_props$theme9 = props.theme) === null || _props$theme9 === void 0 ? void 0 : (_props$theme9$carouse = _props$theme9.carousel) === null || _props$theme9$carouse === void 0 ? void 0 : _props$theme9$carouse.nvaHeroPaginationBorderColor, ";") : '';
}, function (props) {
  var _props$theme10, _props$theme10$carous, _props$theme11, _props$theme11$carous;
  return (_props$theme10 = props.theme) !== null && _props$theme10 !== void 0 && (_props$theme10$carous = _props$theme10.carousel) !== null && _props$theme10$carous !== void 0 && _props$theme10$carous.nvaHeroPaginationInactiveColor ? "background-color: ".concat((_props$theme11 = props.theme) === null || _props$theme11 === void 0 ? void 0 : (_props$theme11$carous = _props$theme11.carousel) === null || _props$theme11$carous === void 0 ? void 0 : _props$theme11$carous.nvaHeroPaginationInactiveColor, ";") : '';
}, breakPoints.desktop, function (props) {
  var _props$theme12, _props$theme12$carous, _props$theme13, _props$theme13$carous;
  return (_props$theme12 = props.theme) !== null && _props$theme12 !== void 0 && (_props$theme12$carous = _props$theme12.carousel) !== null && _props$theme12$carous !== void 0 && _props$theme12$carous.nvaHeroPaginationInactiveColor ? "background-color: ".concat((_props$theme13 = props.theme) === null || _props$theme13 === void 0 ? void 0 : (_props$theme13$carous = _props$theme13.carousel) === null || _props$theme13$carous === void 0 ? void 0 : _props$theme13$carous.nvaHeroPaginationInactiveColor, ";") : '';
}, function (props) {
  var _props$theme14, _props$theme14$carous, _props$theme15, _props$theme15$carous;
  return (_props$theme14 = props.theme) !== null && _props$theme14 !== void 0 && (_props$theme14$carous = _props$theme14.carousel) !== null && _props$theme14$carous !== void 0 && _props$theme14$carous.nvaHeroPaginationActiveColor ? "background-color: ".concat((_props$theme15 = props.theme) === null || _props$theme15 === void 0 ? void 0 : (_props$theme15$carous = _props$theme15.carousel) === null || _props$theme15$carous === void 0 ? void 0 : _props$theme15$carous.nvaHeroPaginationActiveColor, ";") : '';
}, breakPoints.desktop, function (props) {
  var _props$theme16, _props$theme16$carous, _props$theme17, _props$theme17$carous;
  return (_props$theme16 = props.theme) !== null && _props$theme16 !== void 0 && (_props$theme16$carous = _props$theme16.carousel) !== null && _props$theme16$carous !== void 0 && _props$theme16$carous.nvaHeroPaginationActiveColor ? "background-color: ".concat((_props$theme17 = props.theme) === null || _props$theme17 === void 0 ? void 0 : (_props$theme17$carous = _props$theme17.carousel) === null || _props$theme17$carous === void 0 ? void 0 : _props$theme17$carous.nvaHeroPaginationActiveColor, ";") : '';
}, function (props) {
  var _props$theme18, _props$theme18$carous, _props$theme19, _props$theme19$carous;
  return (_props$theme18 = props.theme) !== null && _props$theme18 !== void 0 && (_props$theme18$carous = _props$theme18.carousel) !== null && _props$theme18$carous !== void 0 && _props$theme18$carous.nvaHeroPaginationActiveColor ? "background-color: ".concat((_props$theme19 = props.theme) === null || _props$theme19 === void 0 ? void 0 : (_props$theme19$carous = _props$theme19.carousel) === null || _props$theme19$carous === void 0 ? void 0 : _props$theme19$carous.nvaHeroPaginationActiveColor, ";") : '';
}, breakPoints.desktop, function (props) {
  var _props$theme20, _props$theme20$carous, _props$theme21, _props$theme21$carous;
  return (_props$theme20 = props.theme) !== null && _props$theme20 !== void 0 && (_props$theme20$carous = _props$theme20.carousel) !== null && _props$theme20$carous !== void 0 && _props$theme20$carous.nvaHeroPaginationActiveColor ? "background-color: ".concat((_props$theme21 = props.theme) === null || _props$theme21 === void 0 ? void 0 : (_props$theme21$carous = _props$theme21.carousel) === null || _props$theme21$carous === void 0 ? void 0 : _props$theme21$carous.nvaHeroPaginationActiveColor, ";") : '';
}, breakPoints.desktop);
export default styles;