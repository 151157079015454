export var imageRenditions = {
  desktopL: {
    h: '290',
    w: '198'
  },
  desktop: {
    h: '290',
    w: '198'
  },
  tabletL: {
    h: '290',
    w: '180'
  },
  tabletP: {
    h: '365',
    w: '280'
  },
  mobile: {
    h: '365',
    w: '280'
  }
};
export var imageRenditionsEquine = {
  desktopL: {
    h: '469',
    w: '320'
  },
  desktop: {
    h: '469',
    w: '320'
  },
  tabletL: {
    h: '395',
    w: '270'
  },
  tabletP: {
    h: '586',
    w: '450'
  },
  mobile: {
    h: '586',
    w: '450'
  }
};
export var imageRenditionsVariationB = {
  desktopL: {
    h: '198',
    w: '198'
  },
  desktop: {
    h: '180',
    w: '180'
  },
  tabletL: {
    h: '280',
    w: '280'
  },
  tabletP: {
    h: '280',
    w: '280'
  },
  mobile: {
    h: '280',
    w: '280'
  }
};