import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "headline", "subheadline", "subPara", "bodyText", "video", "carouselImages", "carouselSettings", "srcSetImg", "videoSetting"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './ImageSliderTextCard.style';
import RichText from '../../atoms/RichText/RichText';
import Heading from '../../atoms/Heading';
import Para from '../../atoms/Para';
import Carousel from '../../molecules/Carousel';
import Image from '../../atoms/Image';
import Media from '../../molecules/Media';
var ImageSliderTextCard = function ImageSliderTextCard(_ref) {
  var className = _ref.className,
    headline = _ref.headline,
    subheadline = _ref.subheadline,
    subPara = _ref.subPara,
    bodyText = _ref.bodyText,
    video = _ref.video,
    carouselImages = _ref.carouselImages,
    carouselSettings = _ref.carouselSettings,
    srcSetImg = _ref.srcSetImg,
    videoSetting = _ref.videoSetting,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx("div", {
    className: "nva-image-slider-text-card ".concat(className)
  }, __jsx("div", {
    className: "textCard"
  }, headline && __jsx("div", {
    className: "i_s_headingClass"
  }, __jsx(Heading, {
    HeadingType: "h2",
    className: "nva-image-slider-text-card__heading textCardContent heading_class"
  }, headline)), subheadline && __jsx("div", {
    className: "i_s_subheading_class"
  }, __jsx(Heading, {
    HeadingType: "h4",
    className: "nva-image-slider-text-card__sub-heading textCardContent subheading_class"
  }, subheadline)), subPara && __jsx("div", {
    className: "i_s_subPara_class"
  }, __jsx(Para, {
    className: "nva-image-slider-text-card__sub-para textCardContent heading_four subPara_class"
  }, subPara)), bodyText && __jsx("div", {
    className: "i_s_para_class"
  }, __jsx(RichText, {
    className: "nva-image-slider-text-card__body-text textCardContent para_class",
    jsonContent: bodyText
  }))), video && __jsx(Media, {
    video: video,
    videoSetting: {
      fs: 1
    },
    className: "nva-image-slider-text-card__video variation-a",
    styleType: "videoSliderImage",
    srcSetSetting: {
      imageRenditions: srcSetImg
    }
  }), carouselImages && !video && __jsx("div", {
    className: "i_s_carousel_class"
  }, __jsx(Carousel, _extends({
    className: "nva-image-slider-text-card__carousel image-cards",
    styleType: "ImageSliderCarousel"
  }, carouselSettings), carouselImages && carouselImages.map(function (carddata, index) {
    var imageSrc = {
      src: carddata.image.url,
      alt: carddata.altText,
      title: carddata.title || carddata.altText,
      srcSetSettingImg: {
        imageRenditions: srcSetImg
      }
    };
    return imageSrc.src ? __jsx(Image, _extends({
      key: index
    }, imageSrc)) : null;
  }))));
};
ImageSliderTextCard.defaultProps = {
  className: '',
  styleType: ''
};
export default styled(ImageSliderTextCard).withConfig({
  componentId: "sc-15e9nrl-0"
})(["", ";"], styles);
export { ImageSliderTextCard as CarouselVanilla };