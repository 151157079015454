var __jsx = React.createElement;
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import styles from './Modal.style';
import { focusHelpers } from '../../../utils/index';
var cleanPreviousFocusState = focusHelpers.cleanPreviousFocusState,
  enforceFocus = focusHelpers.enforceFocus,
  setLastActiveFocus = focusHelpers.setLastActiveFocus;
var ModalSection = styled.section.withConfig({
  componentId: "sc-zpo6v-0"
})(["", ";"], styles);
var Modal = function Modal(_ref) {
  var children = _ref.children,
    modalActive = _ref.modalActive,
    onClose = _ref.onClose;
  var wrapperRef = useRef(null);
  var closeRef = useRef(null);
  var enforceWrapper = function enforceWrapper(event) {
    return enforceFocus(event, wrapperRef === null || wrapperRef === void 0 ? void 0 : wrapperRef.current, closeRef === null || closeRef === void 0 ? void 0 : closeRef.current);
  };
  useEffect(function () {
    var _wrapperRef$current, _document;
    if (typeof window === 'undefined') return;
    setLastActiveFocus();
    wrapperRef === null || wrapperRef === void 0 ? void 0 : (_wrapperRef$current = wrapperRef.current) === null || _wrapperRef$current === void 0 ? void 0 : _wrapperRef$current.focus();

    // Enforce the focus inside the modal
    (_document = document) === null || _document === void 0 ? void 0 : _document.addEventListener('focus', enforceWrapper, true);
    return function () {
      var _document2;
      (_document2 = document) === null || _document2 === void 0 ? void 0 : _document2.removeEventListener('focus', enforceWrapper, true);
      cleanPreviousFocusState();
    };
  }, [modalActive]);
  return modalActive && __jsx(ModalSection, {
    className: "nva-modal modal molecule",
    ref: wrapperRef,
    role: "dialog",
    "aria-modal": "true"
  }, __jsx("div", {
    className: "nva-modal__wrapper modal-inline"
  }, __jsx("div", {
    className: "nva-modal__inner-wrapper modal-parent"
  }, __jsx("button", {
    "aria-label": "Close",
    tabIndex: "0",
    id: "modal-deactivate",
    className: "nva-modal__close-button modal-deactivate",
    onClick: function onClick() {
      return onClose();
    },
    ref: closeRef
  }, "\xA0"), __jsx("div", {
    id: "generic-modal",
    className: "nva-modal__content-wrapper generic-modal"
  }, __jsx("div", {
    className: "nva-modal__body modal-body"
  }, children)))));
};
export default styled(Modal).withConfig({
  componentId: "sc-zpo6v-1"
})(["", ";"], styles);
export { Modal as ModalVanilla };