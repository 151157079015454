export var mediaTextCarouselHeight = {
  large: 567,
  medium: 405,
  small: 289
};
export var heroMobileFullVariation = {
  large: 500,
  medium: 400,
  small: 300
};
export var getImageRenditions = function getImageRenditions(height, mobileHeroVariation) {
  return {
    desktopL: {
      h: mediaTextCarouselHeight[height],
      w: 1600
    },
    desktop: {
      h: mediaTextCarouselHeight[height],
      w: 1485
    },
    tabletL: {
      h: mediaTextCarouselHeight[height],
      w: 1195
    },
    tabletP: {
      h: mobileHeroVariation === 'split' ? 420 : heroMobileFullVariation[height],
      w: 800
    },
    mobile: {
      h: mobileHeroVariation === 'split' ? 250 : heroMobileFullVariation[height],
      w: 414
    }
  };
};
export var getImageRenditionsVariationC = function getImageRenditionsVariationC(height, mobileHeroVariation) {
  return {
    desktopL: {
      h: mediaTextCarouselHeight[height],
      w: 880
    },
    desktop: {
      h: mediaTextCarouselHeight[height],
      w: 816
    },
    tabletL: {
      h: mediaTextCarouselHeight[height],
      w: 658
    },
    tabletP: {
      h: mobileHeroVariation === 'split' ? 420 : heroMobileFullVariation[height],
      w: 800
    },
    mobile: {
      h: mobileHeroVariation === 'split' ? 250 : heroMobileFullVariation[height],
      w: 414
    }
  };
};