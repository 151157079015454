import { css } from 'styled-components';
import { getBackgroundStyle } from '../../../utils/utilityFunction';
import breakpoints from '../../../themes/breakpoints';
var styles = css(["", ";.textCard{padding:0 20px;text-align:center;.textCardContent{display:inline-block;margin-bottom:20px;ul{li{display:block;p{display:list-item;list-style-type:'\u2022  ';list-style-position:inside;}}}ol{li{display:block;p{display:list-item;list-style-position:inside;}}}}.heading_class{margin-bottom:10px;}.subheading_class{margin-bottom:20px;}.para_class{p{margin-bottom:40px;}}}"], function (props) {
  var _props$theme, _props$theme$imageSli, _props$theme2, _props$theme2$imageSl, _props$theme3, _props$theme4;
  var overrideBackground = props.overrideBackground;
  var overrideId = overrideBackground ? overrideBackground.backgroundId : '';
  var backgroundColor = (_props$theme = props.theme) !== null && _props$theme !== void 0 && (_props$theme$imageSli = _props$theme.imageSlider) !== null && _props$theme$imageSli !== void 0 && _props$theme$imageSli.primaryBgColor ? "var(--sc-image-slider--primary-bg-color, ".concat((_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : (_props$theme2$imageSl = _props$theme2.imageSlider) === null || _props$theme2$imageSl === void 0 ? void 0 : _props$theme2$imageSl.primaryBgColor, ")") : '';
  return getBackgroundStyle(overrideId, '', backgroundColor, (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : _props$theme3.palette, (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : _props$theme4.textures);
});
export default styles;