var __jsx = React.createElement;
/**
 * Represents Services Blade Component.
 * @author kkum
 */import React from 'react';
import styled from 'styled-components';
import styles from './ServicesBlade.style';
import TextCard from '../../molecules/TextCard';
import Heading from '../../atoms/Heading';
import RichText from '../../atoms/RichText/RichText';
import Image from '../../atoms/Image';
import constants, { siteConstants } from '../../../constants/globalConstants';
import analyticsConstants from '../../../constants/analyticsConstants';
import { servicesBladeLabels } from '../../../constants/labels/en';
import Anchor from '../../atoms/Anchor';
import { createLinkID, stripHashfromStart } from '../../../utils/utilityFunction';
// import slugify from 'url-slug';
import urlSlug from 'url-slug';
import { imageRenditions } from './config';
var ServicesBlade = function ServicesBlade(_ref) {
  var _servicesBladeData$sy, _serviceTileCollectio;
  var servicesBladeData = _ref.servicesBladeData,
    pageUrl = _ref.pageUrl,
    className = _ref.className;
  if (!servicesBladeData) {
    return null;
  }
  var serviceTileCollection = servicesBladeData.serviceTileCollection,
    sectionHeadline = servicesBladeData.sectionHeadline,
    anchorLink = servicesBladeData.anchorLink,
    bodyText = servicesBladeData.bodyText,
    hideBlade = servicesBladeData.hideBlade;
  var jumpLinkId = createLinkID(stripHashfromStart(anchorLink)) || createLinkID(sectionHeadline);
  return __jsx("div", {
    className: "nva-service-blade ".concat(className),
    id: servicesBladeData === null || servicesBladeData === void 0 ? void 0 : (_servicesBladeData$sy = servicesBladeData.sys) === null || _servicesBladeData$sy === void 0 ? void 0 : _servicesBladeData$sy.id,
    style: {
      display: hideBlade ? 'none' : null
    }
  }, __jsx("div", {
    className: "services-blade container"
  }, __jsx("div", {
    className: "service-container"
  }, sectionHeadline && __jsx(Heading, {
    HeadingType: "h2",
    className: "nva-service-blade__heading blade-heading",
    id: jumpLinkId
  }, sectionHeadline), __jsx(RichText, {
    jsonContent: bodyText && bodyText.json,
    className: "nva-service-blade__description blade-description"
  }), __jsx("div", {
    className: "service-tile-container"
  }, serviceTileCollection === null || serviceTileCollection === void 0 ? void 0 : (_serviceTileCollectio = serviceTileCollection.items) === null || _serviceTileCollectio === void 0 ? void 0 : _serviceTileCollectio.map(function (tileInitial, index) {
    var _tile$galleryCollecti, _tile$galleryCollecti2, _tile$galleryCollecti3, _tile$galleryCollecti4, _tile$galleryCollecti5, _tile$galleryCollecti6, _tile$galleryCollecti7, _tile$galleryCollecti8, _tile$galleryCollecti9, _tile$galleryCollecti10, _tile$galleryCollecti11, _tile$galleryCollecti12, _tile$galleryCollecti13;
    var tile = tileInitial === null || tileInitial === void 0 ? void 0 : tileInitial.serviceDetails;
    var serviceURL = tile && tile !== null && tile !== void 0 && tile.serviceUrl && ((tile === null || tile === void 0 ? void 0 : tile.serviceUrl[0]) == '/' || (tile === null || tile === void 0 ? void 0 : tile.serviceUrl[0]) == '#') ? tile === null || tile === void 0 ? void 0 : tile.serviceUrl.substring(1) : tile === null || tile === void 0 ? void 0 : tile.serviceUrl;
    var divider = serviceURL && (tile === null || tile === void 0 ? void 0 : tile.serviceUrl[0]) == '#' ? '#' : '/';
    var cta = {
      label: servicesBladeLabels.learnMore,
      url: "".concat(pageUrl).concat(divider).concat(serviceURL) || "/".concat(constants.pageName.services, "/").concat(urlSlug(tile.serviceName))
    };
    var imageSrc = tile === null || tile === void 0 ? void 0 : (_tile$galleryCollecti = tile.galleryCollection) === null || _tile$galleryCollecti === void 0 ? void 0 : (_tile$galleryCollecti2 = _tile$galleryCollecti.items) === null || _tile$galleryCollecti2 === void 0 ? void 0 : (_tile$galleryCollecti3 = _tile$galleryCollecti2[0]) === null || _tile$galleryCollecti3 === void 0 ? void 0 : (_tile$galleryCollecti4 = _tile$galleryCollecti3.image) === null || _tile$galleryCollecti4 === void 0 ? void 0 : _tile$galleryCollecti4.url;
    var altText = tile === null || tile === void 0 ? void 0 : (_tile$galleryCollecti5 = tile.galleryCollection) === null || _tile$galleryCollecti5 === void 0 ? void 0 : (_tile$galleryCollecti6 = _tile$galleryCollecti5.items) === null || _tile$galleryCollecti6 === void 0 ? void 0 : (_tile$galleryCollecti7 = _tile$galleryCollecti6[0]) === null || _tile$galleryCollecti7 === void 0 ? void 0 : _tile$galleryCollecti7.altText;
    var imgTitle = (tile === null || tile === void 0 ? void 0 : (_tile$galleryCollecti8 = tile.galleryCollection) === null || _tile$galleryCollecti8 === void 0 ? void 0 : (_tile$galleryCollecti9 = _tile$galleryCollecti8.items) === null || _tile$galleryCollecti9 === void 0 ? void 0 : (_tile$galleryCollecti10 = _tile$galleryCollecti9[0]) === null || _tile$galleryCollecti10 === void 0 ? void 0 : _tile$galleryCollecti10.title) || (tile === null || tile === void 0 ? void 0 : (_tile$galleryCollecti11 = tile.galleryCollection) === null || _tile$galleryCollecti11 === void 0 ? void 0 : (_tile$galleryCollecti12 = _tile$galleryCollecti11.items) === null || _tile$galleryCollecti12 === void 0 ? void 0 : (_tile$galleryCollecti13 = _tile$galleryCollecti12[0]) === null || _tile$galleryCollecti13 === void 0 ? void 0 : _tile$galleryCollecti13.altText);
    if (!(tileInitial !== null && tileInitial !== void 0 && tileInitial.hideService)) {
      var _tileInitial$sys, _tileInitial$sys2, _tileInitial$sys3, _analyticsConstants$s, _analyticsConstants$s2, _tile$shortDescriptio, _tile$serviceOverview, _analyticsConstants$s3, _analyticsConstants$s4;
      return __jsx(Anchor, {
        "data-entry-id": tileInitial === null || tileInitial === void 0 ? void 0 : (_tileInitial$sys = tileInitial.sys) === null || _tileInitial$sys === void 0 ? void 0 : _tileInitial$sys.id,
        key: tileInitial === null || tileInitial === void 0 ? void 0 : (_tileInitial$sys2 = tileInitial.sys) === null || _tileInitial$sys2 === void 0 ? void 0 : _tileInitial$sys2.id,
        className: "nva-service-blade__tile service-tile",
        id: tileInitial === null || tileInitial === void 0 ? void 0 : (_tileInitial$sys3 = tileInitial.sys) === null || _tileInitial$sys3 === void 0 ? void 0 : _tileInitial$sys3.id,
        title: "Click Here to Learn More about ".concat(tile === null || tile === void 0 ? void 0 : tile.serviceName),
        to: cta === null || cta === void 0 ? void 0 : cta.url,
        ctaBehavior: cta === null || cta === void 0 ? void 0 : cta.behavior,
        hyperlinkType: cta === null || cta === void 0 ? void 0 : cta.hyperlinkType,
        "aria-label": tile === null || tile === void 0 ? void 0 : tile.ariaLabel,
        "data-analytics-type": analyticsConstants === null || analyticsConstants === void 0 ? void 0 : (_analyticsConstants$s = analyticsConstants.servicesBlade) === null || _analyticsConstants$s === void 0 ? void 0 : (_analyticsConstants$s2 = _analyticsConstants$s.analyticsType) === null || _analyticsConstants$s2 === void 0 ? void 0 : _analyticsConstants$s2.CTA_SERVICES_TITLE,
        "data-analytics-value": tile === null || tile === void 0 ? void 0 : tile.serviceName,
        "data-analytics-variation": ""
      }, __jsx("div", {
        className: "nva-service-blade__image image-wrapper"
      }, __jsx(Image, {
        src: imageSrc,
        alt: altText,
        title: imgTitle,
        srcSetSettingImg: {
          imageRenditions: imageRenditions
        }
      })), __jsx(TextCard, {
        className: "nva-service-blade__text service-text",
        headingText: tile === null || tile === void 0 ? void 0 : tile.serviceName,
        headingType: "h3",
        descriptionType: siteConstants.RICH_TEXT,
        descriptionText: (tile === null || tile === void 0 ? void 0 : (_tile$shortDescriptio = tile.shortDescription) === null || _tile$shortDescriptio === void 0 ? void 0 : _tile$shortDescriptio.json) || (tile === null || tile === void 0 ? void 0 : (_tile$serviceOverview = tile.serviceOverview) === null || _tile$serviceOverview === void 0 ? void 0 : _tile$serviceOverview.json),
        cta: cta,
        isAlreadyWraped: true,
        "data-analytics-type": analyticsConstants === null || analyticsConstants === void 0 ? void 0 : (_analyticsConstants$s3 = analyticsConstants.servicesBlade) === null || _analyticsConstants$s3 === void 0 ? void 0 : (_analyticsConstants$s4 = _analyticsConstants$s3.analyticsType) === null || _analyticsConstants$s4 === void 0 ? void 0 : _analyticsConstants$s4.CTA_SERVICES_TITLE,
        "data-analytics-value": tile === null || tile === void 0 ? void 0 : tile.serviceName,
        "data-analytics-variation": ""
      }));
    }
  })))));
};
ServicesBlade.defaultProps = {
  className: ''
};
export default styled(ServicesBlade).withConfig({
  componentId: "sc-5trg3i-0"
})(["", ";"], styles);