import _slicedToArray from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["address", "theme"];
var __jsx = React.createElement;
import React, { useState, useEffect } from 'react';
import { StaticGoogleMap, Marker } from 'react-static-google-map';
import styled from 'styled-components';
import styles from './StaticGoogleMaps.style';
import Anchor from '../../atoms/Anchor';
import { linkTargetWindow } from '../../../constants/globalConstants';
import { getGoogleStaticMapsApiKey } from '../../../utils/utilityFunction';
var GoogleMapsBlock = styled.section.withConfig({
  componentId: "sc-1xcbt49-0"
})(["", ";"], styles);
var StaticGoogleMaps = function StaticGoogleMaps(_ref) {
  var address = _ref.address,
    theme = _ref.theme,
    others = _objectWithoutProperties(_ref, _excluded);
  var _useState = useState('29229+canwood st+agoura hills+ca+91301'),
    addressString = _useState[0],
    setAddressString = _useState[1];
  var _useState2 = useState('640x640'),
    imageSize = _useState2[0],
    setImageSize = _useState2[1];
  var _useState3 = useState('2'),
    imageScale = _useState3[0],
    setImageScale = _useState3[1];
  var googleStaticMapsApiKey = getGoogleStaticMapsApiKey();
  useEffect(function () {
    if (address && address.line1) {
      setAddressString(getAddressString(address));
    }
  }, [address]);
  useEffect(function () {
    var updateWindowDimensions = function updateWindowDimensions() {
      var newWidth = window.innerWidth;
      var _getMapSizeScale = getMapSizeScale(newWidth),
        _getMapSizeScale2 = _slicedToArray(_getMapSizeScale, 2),
        size = _getMapSizeScale2[0],
        scale = _getMapSizeScale2[1];
      setImageSize(size);
      setImageScale(scale);
    };
    window.addEventListener('resize', updateWindowDimensions);
    return function () {
      return window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);
  var getAddressString = function getAddressString(address) {
    var addressString = '';
    addressString = "".concat(address.line1 ? address.line1 + '+' : '', "\n    ").concat(address.line2 ? address.line2 + '+' : '', "\n    ").concat(address.city ? address.city + '+' : '', "\n    ").concat(address.region ? address.region + '+' : '', "\n    ").concat(address.postalCode ? address.postalCode + '+' : '');
    return addressString;
  };
  var getMapSizeScale = function getMapSizeScale(width) {
    if (width >= 1280) {
      return ['640x367', 2];
    } else {
      return ["".concat(Math.round(width / 2), "x367"), 2];
    }
  };
  var formatDirectionsAddress = function formatDirectionsAddress(addressStr) {
    return addressStr ? "".concat(addressStr.line1 ? addressStr.line1.split(' ').join('+') : '', "+").concat(addressStr.city, "+").concat(addressStr.region, "+").concat(addressStr.postalCode) : '';
  };
  var directionsUrl = address && "https://www.google.com/maps/dir/?daddr=".concat(formatDirectionsAddress(address));
  return address ? __jsx(GoogleMapsBlock, {
    className: "nva-maps"
  }, __jsx(Anchor, {
    className: "nva-maps__map",
    title: addressString,
    ctaBehavior: linkTargetWindow.newWindow,
    href: directionsUrl,
    to: directionsUrl,
    style: {
      display: 'unset'
    }
  }, __jsx(StaticGoogleMap, {
    size: imageSize
    // size="640x640"
    ,
    scale: imageScale,
    zoom: "16",
    maptype: "roadmap",
    className: "map-wrapper"
    // className="snazzy-maps-iframe"
    ,
    apiKey: googleStaticMapsApiKey,
    style: 'feature:poi|visibility:off'
  }, __jsx(Marker, {
    className: "nva-maps__map-marker",
    location: addressString,
    color: "red",
    size: "small"
  })))) : null;
};
export default styled(StaticGoogleMaps).withConfig({
  componentId: "sc-1xcbt49-1"
})(["", ";"], styles);