var previousFocus;
var lastFocusBeforeEnforcement;
function enforceFocus(event, wrapper, lastElement) {
  var _document2;
  if (document !== event.target && wrapper !== event.target && !(wrapper !== null && wrapper !== void 0 && wrapper.contains(event.target))) {
    var _document;
    if (previousFocus === lastElement) {
      previousFocus = wrapper;
      return wrapper === null || wrapper === void 0 ? void 0 : wrapper.focus(); // when user press tab
    }

    if (((_document = document) === null || _document === void 0 ? void 0 : _document.activeElement) !== wrapper) {
      previousFocus = lastElement;
      return lastElement === null || lastElement === void 0 ? void 0 : lastElement.focus(); // when user press shift + tab
    }
  }

  previousFocus = (_document2 = document) === null || _document2 === void 0 ? void 0 : _document2.activeElement;
}

// To go back to this element when the modal is closed
var setLastActiveFocus = function setLastActiveFocus() {
  var _document3;
  lastFocusBeforeEnforcement = (_document3 = document) === null || _document3 === void 0 ? void 0 : _document3.activeElement;
};
var cleanPreviousFocusState = function cleanPreviousFocusState() {
  var _lastFocusBeforeEnfor;
  previousFocus = null;
  (_lastFocusBeforeEnfor = lastFocusBeforeEnforcement) === null || _lastFocusBeforeEnfor === void 0 ? void 0 : _lastFocusBeforeEnfor.focus();
};
export default {
  enforceFocus: enforceFocus,
  setLastActiveFocus: setLastActiveFocus,
  cleanPreviousFocusState: cleanPreviousFocusState
};