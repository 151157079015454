export var imageRenditions = {
  desktopL: {
    w: '337',
    h: '257'
  },
  desktop: {
    w: '337',
    h: '257'
  },
  tabletL: {
    w: '308',
    h: '236'
  },
  tabletP: {
    w: '280',
    h: '208'
  },
  mobile: {
    w: '280',
    h: '202'
  }
};